import SimpleLayout from "../../layout/SimpleLayout"
import SbEditable from "@mkt/ui/components/common/SbEditable"

const Widget = ({ pageContext, location }) => {
  return (
    <SimpleLayout>
      <SbEditable content={pageContext?.content}>
        <div></div>
      </SbEditable>
    </SimpleLayout>
  )
}

export default Widget
